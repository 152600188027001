export default function ({ router }) {

  function legacyRouterMode ({ fullPath }, from, next) {
    if (fullPath.substr(0, 2) === '/#') {
      const path = fullPath.substr(2)
      next(path)
      return
    }
    next()
  }

  function skipHome(to, from, next) {
    if (to.name === 'home') {
      window.scrollTo(0, 0)
      return next({ name: 'list'})
    }

    if (to.name === 'acerca' || to.name === 'perfil' || to.name === 'miunivisionario_nominado') {

      window.scrollTo(0,0)

      return next()
    }
    return next()
  }

  router.beforeEach(legacyRouterMode)
  router.beforeEach(skipHome)

  return router
}
