import '@/plugins/bootstrap-vue'

import Vue from 'vue'
import VueRouter from 'vue-router'
import vueNumeralFilterInstaller from 'vue-numeral-filter'
import VueI18n from 'vue-i18n'
import VueSocialSharing from 'vue-social-sharing'
import PortalVue from 'portal-vue'

import routes from './router'
import guards from './router/guards'
import './main.scss'


Vue.use(PortalVue)
Vue.use(VueSocialSharing)
Vue.use(VueRouter)
Vue.use(VueI18n)
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.config.productionTip = false

const i18n = new VueI18n({ locale: 'es' })

const render = h => h('div', { attrs: { id: 'app' } }, [
  h('RouterView')
])

const mode = ''
const router = guards({ router: new VueRouter({ routes, mode }), i18n })

new Vue({ router, render, i18n }).$mount('#app')