export const routes = [
  { 
    path: '/',
    name: 'home'
  },
  {
    path: '/',
    props: true,
    component: () => import('@/App'),
    children: [
      {
        path: '',
        name: 'list',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'univisionarios',
        name: 'univisionarios',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'nominacion',
        name: 'nominacion',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'acerca-de',
        name: 'acerca',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/About'),
        }
      },
      {
        path: 'nominados/:id',
        name: 'perfil',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/PerfilNominado')
        },
      },
      {
        path: 'nominados',
        name: 'nominados',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Nominados')
        }
      },
      {
        path: 'comite',
        name: 'jurado',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'homenajeados',
        name: 'homenajeados',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'categorias/mi-univisionario',
        name: 'miunivisionarios',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'categorias/pioneros',
        name: 'pioneros',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'categorias/innovadores',
        name: 'innovadores',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'categorias/emprendedor',
        name: 'emprendedor',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'categorias/maestros',
        name: 'maestros',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'iconos',
        name: 'iconos',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/Home'),
        }
      },
      {
        path: 'miunivisionario/:id',
        name: 'miunivisionario_nominado',
        props: {
          default: true,
          navigation: true
        },
        components: {
          default: () => import('@/pages/PerfilMiUnivisionario'),
        }
      } 
    ]
  }
]

export default routes
